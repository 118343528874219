<template>
  <div :key="key">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  computed: {
    key() {
      return this.$store.state.app.mainKey;
    },
  },
};
</script>

<style>
</style>